export default {
    "logo": { "name": "تیکتوک", "msg": "مرکز سفارش Tiktok", "wz": "url:" },
    "head": { "Language": "چینی ها" },
    "menu": {
      "home": "صفحه اول",
      "order": "سفارش",
      "trading": "تجارت",
      "earnings": "تیم",
      "mine": "کاربر"
    },
    "back": {
      "appDownload": "بارگیری برنامه",
      "help": "کمک",
      "order": "سفارش",
      "trading": "تجارت",
      "income": "تیم",
      "revenueDetails": "شرح",
      "Withdrawal": "کنار کشیدن",
      "Recharge": "بالا بردن",
      "Invitefriends": "دوستان را دعوت کنید",
      "personalinformation": "اطلاعات شخصی",
      "gywm": "درباره ما",
      "kf": "خدمات مشتری"
    },
    "loading": {
      "loadingText": "بارگذاری...",
      "finishedText": "بیشتر نه",
      "loadingText2": "تطابق ...",
      "loadingLogin": "ورود به سیستم در...",
      "loadingSign": "ثبت نام ...",
      "confirmOrder": "در معامله ..."
    },
    "Toast": { "copy": "تکرار", "ts": "لطفا زبان را انتخاب کنید" },
    "login": {
      "title": "سلام",
      "msg": "وارد حساب کاربری خود شوید",
      "phone": "تلفن",
      "phoneCode": "رمز منطقه",
      "password": "کلمه عبور",
      "remember": "مرا به خاطر بسپار",
      "forget": "رمز را فراموش می کنید؟",
      "loginBtn": "وارد شدن",
      "noAccount": "بدون حساب؟",
      "goRegister": "ثبت نام"
    },
    "sign": {
      "phone": "شماره تلفن",
      "phoneCode": "رمز منطقه",
      "Nickname": "نام مستعار",
      "code": "کد تایید",
      "sendCode": "کد تأیید را ارسال کنید",
      "password": "کلمه عبور",
      "confirmPassword": "تأیید رمز عبور",
      "recommendationCode": "کد توصیه",
      "protocol": "ثبت نام یعنی شما با شرایط و ضوابط ما موافق هستید",
      "registerBtn": "ثبت نام",
      "Existing": "حساب موجود؟",
      "goLogin": "وارد شدن",
      "rule": {
        "phone": "شماره تلفن همراه نمی تواند خالی باشد ، فرمت شماره تلفن همراه نادرست است",
        "nickname": "نام مستعار باید پر شود",
        "password": "رمز عبور نمی تواند خالی باشد ، رمز عبور حداقل 6 رقم است و رمز عبور متناقض است.",
        "code": "کد تأیید باید پر شود",
        "isAgree": "لطفاً ابتدا رضایت را بررسی کنید"
      }
    },
    "home": {
      "cumulativeIncome": "درآمد تجمعی",
      "notification": "اعلام کردن",
      "moduleTitle1": "خدمات من",
      "moduleTitle2": "شریک"
    },
    "download": {
      "TIKTOKSHOP": "فروشگاه Tiktok",
      "AndroidDownload": "بارگیری اندرویدی",
      "iOSDownload": "بارگیری اپل"
    },
    "help": {
      "bz2": "مشکل مشترک",
      "bz3": "قوانین تجارت",
      "bz4": "نحوه برداشت پول",
      "bz5": "نحوه شارژ مجدد",
      "bz6": "1. چه ISTIKTOKSHOP؟",
      "bz7": "Tiktokshop یک شرکت ارتقاء بازاریابی است که به بازرگانان فروشگاه آنلاین Tiktokshop از سراسر جهان کمک می کند تا سفارش بیشتری را به دست آورند و داده های مشاهده را از فروشگاه های آنلاین Tiktokshop افزایش دهند. ما متعهد به ایجاد یک مدل بازاریابی سود سه حزب بین Tiktokshop و بازرگانان و مصرف کنندگان هستیم. با ترکیب جدیدترین فناوری Blockchain P2P ، به سرعت مصرف کنندگان و بازرگانان را از طریق USDT (TRC20 ، ERC20) به هم وصل می کند. کاربران ثبت نام شده می توانند کمیسیون سفارشات را دریافت کنند ، در حالی که بازرگانان می توانند داده های فروش فروشگاه را افزایش دهند. این آخرین مدل سود در مدل blockchain اینترنت است!",
      "bz8": "2. چگونه می توان Ontiktokshop را کار کرد؟",
      "bz9": "از طریق بازخورد روزانه Tiktokshop ، محصولاتی که باید در فروش Tiktokshop بهبود یابد ، باید کاربران فقط برای تولید خودکار اشتراک سفارش به راحتی بر روی سفارش کلیک کنند. کاربران مبلغ سفارش را از طریق blockchain USDT پرداخت می کنند و کمیسیون های روزانه دریافت می کنند.",
      "bz10": "3. چرا در دو معاملات تفاوت در قیمت همان ارز وجود دارد؟",
      "bz11": "اختلاف قیمت توسط همه عواملی که منجر به آزادی ارز نمی شوند ، از جمله سرعت انتقال ارز ، شرایط شبکه ، محدودیت های دسترسی به ارز ، افراد در مناطق مختلف و حتی انواع معاملات و معاملات ارائه شده توسط مبادله ایجاد می شود. بنابراین ، همان ارز ممکن است باعث اختلاف قیمت در معاملات مختلف شود.",
      "bz12": "4. سود سرمایه گذاری؟",
      "bz13": "هرچه قیمت شما بالاتر باشد ، سود شما نیز بیشتر می شود. در عین حال ، Tiktokshop به طور تصادفی دستورات بزرگ کمیسیون را اختصاص می دهد.",
      "bz14": "هر روز ، سیستم به طور خودکار 60 سفارش کالا را به کاربر تولید و توزیع می کند. سفارشات کمیسیون بزرگ به طور تصادفی توزیع می شود.",
      "bz15": "USDT را می توان پس از 60 سفارش خارج کرد. (اگر 60 سفارش به پایان نرسد ، سیستم به طور خودکار متوقف می شود تا مبلغ سفارش مازاد روز)",
      "bz16": "هر حساب فقط در صورت بروز خطایی می تواند به یک حساب USDT محدود شود.",
      "bz17": "پس از اتمام تحویل روزانه ، می توانید پس از شروع برداشت ، به طور عادی پس بگیرید.",
      "bz18": "قبل از شارژ ، لطفاً آدرس خروج خود را (پشتیبانی از TRC-20) آدرس USDT وصل کنید.",
    'bz19': "انجام شارژهای متقابل، نقل و انتقالات بانکی، انتقال وجه و غیره بین حساب های مرتبط ممنوع است.",

    },
    "order": {
      "incomplete": "انجام نشده",
      "completed": "کامل",
      "orderNumber": "شماره سفارش",
      "transactionTime": "ساعت معامله",
      "price": "میزان",
      "expectedReturns": "بازده مورد انتظار",
      "submit": "ثبت درخواست"
    },
    "trading": {
      "accountBalance": "موجودی حساب",
      "introduce": "معرفی معامله",
      "freeze": "شماره تکمیل شده",
      "walletBalance": "وظیفه کل",
      "startMatching": "شروع تطبیق",
      "yield": "مزایای امروز",
      "orderNumber": "شماره سفارش",
      "transactionTime": "ساعت معامله",
      "price": "میزان",
      "expectedReturns": "بازده مورد انتظار",
      "startTrading": "تجارت را شروع کنید",
      "cancel": "لغو کردن",
      "ts": "تعادل کافی ، هنوز هم فقیر است",
      "msg": "Tiktokshop هر روز از بازخورد Tiktokshop استفاده می کند تا محصولاتی را نشان دهد که برای فروش Tiktokshop باید بهبود یابد. اگر کاربر فقط نیاز به کلیک بر روی سفارش داشته باشد ، سیستم به طور خودکار امضای سفارش را تولید می کند. کاربر مبلغ سفارش را از طریق blockchain USD پرداخت می کند و می تواند هر بار بیش از 0.6 ٪ کمیسیون را دریافت کند و سیستم به طور تصادفی سفارشات پاداش بزرگ را توزیع می کند."
    },
    "income": {
      "totalRevenue": "تعادل",
      "residualIncome": "راهق",
      "claim": "دريافت كردن",
      "moduleTitle": "تیم من",
      "headcount": "کل مردم",
      "totalRecharge": "شارژ کل",
      "grade": "کاربر",
      "numberOfPeople": "مشارکت (کمیت)",
      "income": "حالت",
      "viewDetails": "دیدن جزئیات",
      "task_num": "انجام نشده",
      "task_num2": "کامل"
    },
    "revenueDetails": {
      "totalRevenue": "کل درآمد",
      "headcount": "کل مردم",
      "totalRecharge": "شارژ کل",
      "userLevel1": "سطح کاربر 1",
      "userLevel2": "سطح کاربر 2",
      "userLevel3": "سطح کاربر 3",
      "Nickname": "نام مستعار",
      "phone": "شماره تلفن",
      "Recharge": "بالا بردن",
      "income": "درآمد",
      "date": "زمان ثبت نام"
    },
    "user": {
      "Myaccount": "حساب من",
      "Withdrawal": "کنار کشیدن",
      "Recharge": "بالا بردن",
      "InviteFriend": "دوستانت را دعوت کن",
      "InviteFriendTxt": "برای هر سود حاصل از دوستان ، کمیسیون نسبت مربوطه را دریافت خواهید کرد",
      "InviteFriendBtn": "اکنون دعوت کنید",
      "MyInformation": "اطلاعات شخصی",
      "FundsDetails": "جزئیات سرمایه",
      "RechargeRecord": "رکورد شارژ",
      "WithdrawalRecord": "رکورد برداشت",
      "Language": "زبان",
      "Logout": "خروج از سیستم"
    },
    "Withdrawal": {
      "address": "آدرس عقب نشینی",
      "placeholder1": "آدرس عقب نشینی",
      "availableBalance": "تعادل موجود",
      "placeholder2": "مبلغ برداشت",
      "actualBalance": "در حقیقت",
      "placeholder3": "در حقیقت",
      "password": "کلمه عبور",
      "placeholder4": "لطفاً رمز ورود 6-16 بیتی را وارد کنید",
      "submit": "ارسال",
      "amount": "بیشتر از مقادیر موجود نیست"
    },
    "Recharge": {
      "qrcode": "شارژ مجدد کد",
      "copy": "برای کپی کلیک کنید",
      "cotakecarepy": "توجه داشته باشید ، یک نکته مرتبط استفاده از صفحه یا صفحه املاک و مستغلات خود است"
    },
    "Invitefriends": {
      "inviteCode": "کد دعوت",
      "inviteTxt": "دوستان را دعوت کنید ، از درآمد زیادی لذت ببرید ، لطفاً دعوت کنید",
      "copy": "برای کپی کلیک کنید",
      "Rebaterules": "تخفیف",
      "RebaterulesMsg": "شرایط زیر باید رعایت شود",
      "scale": "قوانین نسبت کمیسیون به شرح زیر است",
      "Userlevel": "سطح کاربر",
      "Rules": "قانون",
      "Commissionratio": "میزان کمیسیون",
      "ps": "این کمیسیون با USDT تسویه شده است که می تواند در هر زمان به کیف پول شما منتقل شود"
    },
    "personalinformation": {
      "Nickname": "نام مستعار",
      "phone": "شماره تلفن",
      "Loginpassword": "رمز عبور ورود",
      "address": "آدرس عقب نشینی",
      "modify": "تجدید نظر کردن",
      "Confirm": "مطمئن"
    },
    "fundDetails": {
      "amount": "میزان",
      "revenue": "درآمد",
      "time": "زمان",
      "withdrawal": "کنار کشیدن",
      "recharge": "بالا بردن",
      "type": {
        "1": "بالا بردن",
        "2": "کنار کشیدن",
        "3": "مجموعه",
        "4": "درآمد",
        "5": "کنار کشیدن",
        "6": "انتظار",
        "7": "کامل",
        "8": "رد شده"
      }
    },
    "language": {
      "placeholder": "لطفاً کد منطقه بین المللی را وارد کنید",
      "Confirm": "مطمئن",
      "cancel": "لغو کردن"
    },
    "request": {
      "loadingText": "بارگذاری...",
      "Serverexception": "ناهنجاری های سرور"
    },
    "newLang": {
      "a1": "تعادل ضعیف است",
      "a2": "شماره کار فعلی",
      "a3": "لطفاً ابتدا به آدرس خروج الزام آور بروید",
      "a4": "تعادل کافی",
      "a5": "آیا برای شارژ مجدد"
    },
    "newLang2": {
      "a1": "برای آدرس الزام آور ، برای اصلاح با خدمات مشتری تماس بگیرید",
      "a2": "نیاز به شارژ مجدد",
      "a3": "لطفاً برای تغییر رمز عبور با خدمات مشتری تماس بگیرید"
    },
    "selectPay": { "title": "پرداخت", "a1": "ارسال" },
    "newWithdrawal": { "a11": "روش پرداخت","a1": "روش پرداخت" }
  }
  ;

