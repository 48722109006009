export default {
  "logo": { "name": "تيك توك", "msg": "مركز ترتيب تيخوك", "wz": "URL:" },
  "head": { "Language": "عربي" },
  "menu": {
    "home": "الصفحة الأمامية",
    "order": "طلب",
    "trading": "تجارة",
    "earnings": "فريق",
    "mine": "مستخدم"
  },
  "back": {
    "appDownload": "تنزيل التطبيق",
    "help": "يساعد",
    "order": "طلب",
    "trading": "تجارة",
    "income": "فريق",
    "revenueDetails": "التفاصيل",
    "Withdrawal": "ينسحب",
    "Recharge": "فوق حتى",
    "Invitefriends": "ادعو أصدقاء",
    "personalinformation": "معلومات شخصية",
    "gywm": "معلومات عنا",
    "kf": "خدمة الزبائن"
  },
  "loading": {
    "loadingText": "تحميل...",
    "finishedText": "لا أكثر",
    "loadingText2": "مطابقة ...",
    "loadingLogin": "تسجيل الدخول...",
    "loadingSign": "يسجل ...",
    "confirmOrder": "في المعاملة ...",
    "ldg1": "في الانسحاب ..."
  },
  "Toast": { "copy": "تكرار", "ts": "الرجاء تحديد اللغة" },
  "login": {
    "title": "مرحبًا",
    "msg": "تسجيل الدخول إلى حسابك",
    "phone": "حساب",
    "phoneCode": "رمز المنطقة",
    "password": "كلمة المرور",
    "remember": "تذكرنى",
    "forget": "نسيان كلمة المرور؟",
    "loginBtn": "تسجيل الدخول",
    "noAccount": "لا حساب؟",
    "goRegister": "يسجل",
    "lg1": "تسجيل الدخول إلى حساب آخر"
  },
  "sign": {
    "phone": "رقم التليفون",
    "phoneCode": "رمز المنطقة",
    "Nickname": "كنية",
    "code": "رمز التحقق",
    "sendCode": "أرسل رمز التحقق",
    "password": "كلمة المرور",
    "confirmPassword": "تأكيد كلمة المرور",
    "recommendationCode": "رمز التوصية",
    "protocol": "السجل يعني أنك توافق على الشروط والأحكام الخاصة بنا",
    "registerBtn": "يسجل",
    "Existing": "حساب موجود؟",
    "goLogin": "تسجيل الدخول",
    "rule": {
      "phone": "لا يمكن أن يكون الحساب فارغًا ، تنسيق الحساب غير صحيح",
      "nickname": "يجب ملء اللقب",
      "password": "لا يمكن أن تكون كلمة المرور فارغة ، وكلمة المرور أقل من 6 أرقام ، وكلمة المرور غير متسقة.",
      "code": "يجب ملء رمز التحقق",
      "isAgree": "يرجى التحقق من الموافقة أولا"
    }
  },
  "home": {
    "cumulativeIncome": "الدخل التراكمي",
    "notification": "إخطار",
    "moduleTitle1": "خدمتي",
    "moduleTitle2": "شريك",
    "title": "إخطار"
  },
  "download": {
    "TIKTOKSHOP": "متجر Tiktok",
    "AndroidDownload": "تنزيل Android",
    "iOSDownload": "تنزيل Apple"
  },
  "help": {
    "bz2": "مشكلة شائعة",
    "bz3": "قواعد التداول",
    "bz4": "كيفية سحب المال",
    "bz5": "كيفية إعادة الشحن",
    "bz6": "1. ما istiktokshop؟",
    "bz7": "Tiktokshop هي شركة ترويج تسويقية تساعد تجار متجر Tiktokshop عبر الإنترنت من جميع أنحاء العالم للحصول على مبيعات أكثر وزيادة بيانات المشاهدة من متاجر Tiktokshop عبر الإنترنت. نحن ملتزمون بإنشاء نموذج تسويق للربح من ثلاثة أحواض بين Tiktokshop والتجار والمستهلكين. الجمع بين أحدث تقنية blockchain P2P ، وربط بسرعة المستهلكين والتجار من خلال USDT (TRC20 ، ERC20). يمكن للمستخدمين المسجلين الحصول على عمولة الطلبات ، بينما يمكن للتجار زيادة بيانات المبيعات الخاصة بالمتجر. هذا هو أحدث طراز ربح في نموذج blockchain على الإنترنت!",
    "bz8": "2. كيف تعمل ontiktokshop؟",
    "bz9": "من خلال التعليقات اليومية لـ Tiktokshop ، يجب تحسين المنتجات التي تحتاج إلى تحسين في Tiktokshop Sales. يدفع المستخدمون مبلغ الطلب من خلال USDT blockchain والحصول على العمولات اليومية.",
    "bz10": "3. لماذا هناك اختلاف في سعر نفس العملة في المعاملتين؟",
    "bz11": "تحدث اختلافات الأسعار في جميع العوامل التي لا تفضي إلى حرية العملة ، بما في ذلك سرعة نقل العملة ، وظروف الشبكة ، وقيود الوصول إلى العملة ، والأفراد في مناطق مختلفة ، وحتى أنواع التداول والمعاملات التي توفرها البورصة. لذلك ، قد تسبب نفس العملة اختلافات في الأسعار في المعاملات المختلفة.",
    "bz12": "4. ربح الاستثمار؟",
    "bz13": "كلما ارتفع السعر الذي تحصل عليه ، زاد الربح الذي تحصل عليه. في الوقت نفسه ، يخصص Tiktokshop بشكل عشوائي أوامر عمولة كبيرة.",
    "bz14": "كل يوم ، يقوم النظام تلقائيًا بإنشاء وتوزيع 60 طلبًا للسلع على المستخدم. يتم توزيع أوامر عمولة كبيرة بشكل عشوائي.",
    "bz15": "يمكن سحب USDT بعد 60 طلبًا. (إذا لم يتم إكمال أوامر الـ 60 ، فسيتوقف النظام تلقائيًا حتى مبلغ فائض الطلب في اليوم)",
    "bz16": "يمكن أن يكون كل حساب ملزمًا فقط بحساب USDT.",
    "bz17": "بعد الانتهاء من التسليم اليومي ، يمكنك الانسحاب بشكل طبيعي.",
    "bz18": "قبل إعادة الشحن ، يرجى ربط عنوان السحب (دعم TRC-20) USDT.",
    'bz19': "يُحظر إجراء عمليات إعادة الشحن المتبادلة، والتحويلات المصرفية، وتحويلات الصرف، وما إلى ذلك بين الحسابات ذات الصلة. ويُشتبه في أن هذا السلوك يمثل سرقة أوامر ضارة. وإذا تم العثور على مثل هذا السلوك، فسيتم حظر الحساب.",
  },
  "order": {
    "incomplete": "التراجع",
    "completed": "مكتمل",
    "orderNumber": "رقم الأمر",
    "transactionTime": "ساعة المعاملة",
    "price": "كمية",
    "expectedReturns": "العودة المتوقعة",
    "submit": "أكد الطلب"
  },
  "trading": {
    "accountBalance": "رصيد الحساب",
    "introduce": "مقدمة المعاملة",
    "freeze": "رقم مكتمل",
    "walletBalance": "المهمة الكلية",
    "startMatching": "ابدأ بالمطابقة",
    "yield": "فوائد اليوم",
    "orderNumber": "رقم الأمر",
    "transactionTime": "ساعة المعاملة",
    "price": "كمية",
    "expectedReturns": "العودة المتوقعة",
    "startTrading": "بدء التداول",
    "cancel": "يلغي",
    "ts": "توازن غير كافٍ ، لا يزال فقيرًا",
    "msg": "يستخدم Tiktokshop ملاحظات من TiktokShop كل يوم لعرض المنتجات التي تحتاج إلى تحسين لمبيعات Tiktokshop. إذا احتاج المستخدم فقط إلى النقر فوق الطلب ، فسيقوم النظام تلقائيًا بإنشاء توقيع طلب. يدفع المستخدم مبلغ الطلب من خلال usdt blockchain ، ويمكنه الحصول على أكثر من 0.6 ٪ من اللجنة في كل مرة ، ويوزع النظام أوامر المكافآت الكبيرة بشكل عشوائي.",
    "ts2": "تَلمِيح",
    "ts3": "توازن غير كاف",
    "ts4": "اذهب إلى إعادة الشحن",
    "ts5": "يلغي"
  },
  "income": {
    "totalRevenue": "توازن",
    "residualIncome": "عمولة",
    "claim": "يستلم",
    "moduleTitle": "فريقي",
    "headcount": "مجموع الناس",
    "totalRecharge": "إعادة الشحن الكلي",
    "grade": "مستخدم",
    "numberOfPeople": "المساهمة (الكمية)",
    "income": "ولاية",
    "viewDetails": "عرض التفاصيل",
    "task_num": "التراجع",
    "task_num2": "مكتمل"
  },
  "revenueDetails": {
    "totalRevenue": "إجمالي الإيرادات",
    "headcount": "مجموع الناس",
    "totalRecharge": "إعادة الشحن الكلي",
    "userLevel1": "مستوى المستخدم 1",
    "userLevel2": "مستوى المستخدم 2",
    "userLevel3": "مستوى المستخدم 3",
    "Nickname": "كنية",
    "phone": "رقم التليفون",
    "Recharge": "فوق حتى",
    "income": "دخل",
    "date": "وقت التسجيل"
  },
  "user": {
    "Myaccount": "حسابي",
    "Withdrawal": "ينسحب",
    "Recharge": "فوق حتى",
    "InviteFriend": "اعزم أصحابك",
    "InviteFriendTxt": "مقابل كل ربح يكسبه الأصدقاء ، ستحصل على لجنة النسبة المقابلة",
    "InviteFriendBtn": "دعوة الآن",
    "MyInformation": "معلومات شخصية",
    "FundsDetails": "تفاصيل رأس المال",
    "RechargeRecord": "سجل إعادة الشحن",
    "WithdrawalRecord": "سجل السحب",
    "Language": "لغة",
    "Logout": "خروج"
  },
  "Withdrawal": {
    "address": "عنوان السحب",
    "placeholder1": "عنوان السحب",
    "availableBalance": "الرصيد المتوفر",
    "placeholder2": "كمية السحب",
    "actualBalance": "في الحقيقة",
    "placeholder3": "في الحقيقة",
    "password": "كلمة المرور",
    "placeholder4": "الرجاء إدخال كلمة المرور 6-16 بت",
    "submit": "يُقدِّم",
    "amount": "لا أكبر من المبالغ المتاحة",
    "w1": "رسوم المعالجة",
    "ts1": "يرجى الحصول على عنوان السحب",
    "ts2": "الرجاء إدخال مبلغ السحب"
  },
  "Recharge": {
    "qrcode": "إعادة شحن رمز المسح",
    "copy": "انقر لنسخ",
    "cotakecarepy": "ملاحظة ، فإن الاعتبار ذي الصلة هو استخدام شاشتك أو صفحتك العقارية",
    "ts1": "إعادة شحن المبلغ",
    "ts2": "كمية اخرى",
    "ts3": "بالتأكيد",
    "ts4": "الرجاء توصيل المحفظة أولاً",
    "ts5": "الرجاء تحديد مبلغ إعادة الشحن",
    "ts6": "في إعادة الشحن ..."
  },
  "Invitefriends": {
    "inviteCode": "شفرة الدعوة",
    "inviteTxt": "قم بدعوة الأصدقاء ، واستمتع بمبلغ كبير من الدخل ، يرجى دعوة",
    "copy": "انقر لنسخ",
    "Rebaterules": "خصم",
    "RebaterulesMsg": "يجب الوفاء بالشروط التالية",
    "scale": "نسبة قواعد اللجنة هي كما يلي",
    "Userlevel": "مستوى المستخدم",
    "Rules": "قاعدة",
    "Commissionratio": "نسبة العمولة",
    "ps": "يتم تسوية اللجنة مع USDT ، والتي يمكن سحبها إلى محفظتك في أي وقت"
  },
  "personalinformation": {
    "Nickname": "كنية",
    "phone": "حساب",
    "Loginpassword": "كلمة سر الدخول",
    "address": "عنوان السحب",
    "modify": "يراجع",
    "Confirm": "بالتأكيد"
  },
  "fundDetails": {
    "amount": "كمية",
    "revenue": "دخل",
    "time": "وقت",
    "withdrawal": "ينسحب",
    "recharge": "فوق حتى",
    "type": {
      "1": "فوق حتى",
      "2": "ينسحب",
      "3": "جمع اللجنة",
      "4": "دخل",
      "5": "ينسحب",
      "6": "قيد الانتظار",
      "7": "مكتمل",
      "8": "مرفوض"
    }
  },
  "language": {
    "placeholder": "الرجاء إدخال رمز المنطقة الدولية",
    "Confirm": "بالتأكيد",
    "cancel": "يلغي"
  },
  "request": { "loadingText": "تحميل...", "Serverexception": "تشوهات الخادم" },
  "newLang": { "a1": "التوازن ضعيف", "a2": "رقم المهمة الحالي", "a3": "يرجى الانتقال إلى عنوان السحب الملزم أولاً", "a4": "توازن غير كاف", "a5": "سواء لإعادة الشحن" },
  "newLang2": {
    "a1": "للحصول على عنوان الربط ، يرجى الاتصال بخدمة العملاء للتعديل",
    "a2": "تحتاج إلى إعادة الشحن",
    "a3": "يرجى الاتصال بخدمة العملاء لتعديل كلمة المرور"
  },
  "selectPay": { "title": "يدفع", "a1": "يُقدِّم" },
  "newWithdrawal": { "a11": "طريقة الدفع او السداد","a1": "طريقة الدفع او السداد" }
}
